import { AppBar, Button, Toolbar } from "@mui/material";

const PageBottomBtn = ({ disabled, onClick, btnText }) => {
  return (
    <>
      <Toolbar />
      <AppBar
        position="fixed"
        sx={{ top: "auto", bottom: 0, bgcolor: "white" }}
      >
        <Toolbar>
          <Button
            disabled={disabled}
            variant="contained"
            fullWidth
            onClick={onClick}
          >
            {btnText}
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PageBottomBtn;
