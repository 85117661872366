import PageHeading from "../Shared/PageHeading";
import { List, Typography } from "@mui/material";
import PageAppBar from "../Shared/PageAppBar";
import IconMenuItem from "../Shared/IconMenuItem";
import ChatIcon from "@mui/icons-material/Chat";
import EmailIcon from "@mui/icons-material/Email";

const PageHelp = () => {
  return (
    <PageAppBar title="Home">
      <PageHeading>How can we help?</PageHeading>
      <Typography sx={{ mb: 2 }}>
        Select an option to speak to a human
      </Typography>
      <List>
        <IconMenuItem
          Icon={ChatIcon}
          iconColor="primary"
          primary="Chat Us"
          secondary="Chat with us in real time"
          onClick={() => window.Intercom("show")}
        />
        <IconMenuItem
          Icon={ChatIcon}
          iconColor="secondary"
          primary="Text Us"
          secondary="Send us a message through text"
          link="sms:+13137105379"
        />
        <IconMenuItem
          Icon={EmailIcon}
          iconColor="success"
          primary="Email Us"
          secondary="Get a response directly to your inbox"
          link="mailto:help@letshum.com"
        />
      </List>
    </PageAppBar>
  );
};

export default PageHelp;
