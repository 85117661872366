import { useState } from "react";
import { Alert, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeading from "../Shared/PageHeading";
import PageAppBar from "../Shared/PageAppBar";
import { useOutletContext } from "react-router";
import { useNavigate } from "react-router-dom";

const PagePassword = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    submitting: false,
    err: "",
  });
  const { api } = useOutletContext();

  const triggerStartSubmit = () => setPageState({ submitting: true, err: "" });
  const triggerSubmitErr = (msg) =>
    setPageState({ submitting: false, err: msg });

  const handleSubmit = () => {
    triggerStartSubmit();
    api
      .triggerPasswordReset()
      .then((resp) => {
        navigate("/home", {
          state: { snackbar: "Password reset email sent!" },
        });
      })
      .catch((err) => {
        triggerSubmitErr(err.message);
      });
  };

  return (
    <>
      <PageAppBar title="Change Password">
        <PageHeading>Confirm New Password</PageHeading>
        <Typography sx={{ mb: 2 }}>
          This page allows you to change your Hum password. Your Hum password is
          used for both this Resident Portal and the Hum Plume App.
        </Typography>

        {pageState.err && (
          <Alert color="error" sx={{ mb: 2 }}>
            {pageState.err}
          </Alert>
        )}
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          loading={pageState.submitting}
          loadingIndicator="Sending email..."
        >
          Email Me A Change Password Link
        </LoadingButton>
      </PageAppBar>
    </>
  );
};

export default PagePassword;
