import { Typography } from "@mui/material";

const PageHeading = ({ children }) => {
  return (
    <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
      {children}
    </Typography>
  );
};

export default PageHeading;
