import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const IconMenuItem = ({
  Icon,
  iconColor,
  primary,
  secondary,
  link,
  onClick,
}) => {
  return (
    <ListItem
      sx={{
        mt: 1,
        p: 0,
        bgcolor: "white",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: grey[300],
        borderRadius: 2,
      }}
    >
      <ListItemButton
        onClick={
          onClick ||
          ((e) => {
            window.location.href = link;
            e.preventDefault();
          })
        }
      >
        <ListItemIcon>
          <Icon color={iconColor} />
        </ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItemButton>
    </ListItem>
  );
};

export default IconMenuItem;
