import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { verifySessionToken } from "../../publicApi";
import { Typography } from "@mui/material";
import { resiApi } from "../../resiApi";

const LayoutLoggedIn = ({ sessionToken, setSessionToken }) => {
  const navigate = useNavigate();
  const [sessionData, setSessionData] = useState(null);

  useEffect(() => {
    if (!sessionToken) {
      navigate("/");
      return;
    }

    verifySessionToken(sessionToken)
      .then((resp) => {
        setSessionData(resp.data);
      })
      .catch(() => {
        // we ignore the error, but just run home if we do get logged out
        setSessionToken(null);
      });
  }, [sessionToken, setSessionToken, navigate]);

  useEffect(() => {
    if (!sessionData) return;
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: sessionData.email,
      name: `${sessionData.name_first} ${sessionData.name_last}`,
      user_id: sessionData.intrcm_user_id,
    });
  }, [sessionData]);

  if (!sessionData) {
    return <Typography>Loading...</Typography>;
  }

  const onNewSession = (newToken) => {
    setSessionToken(newToken);
  };

  const onSessionFail = () => {
    console.log(`onSessionFail fired`);
    setSessionToken(null);
  };

  return (
    <Outlet
      context={{
        sessionData,
        api: resiApi(sessionToken, onNewSession, onSessionFail),
        setSessionToken,
      }}
    />
  );
};

export default LayoutLoggedIn;
