import PropTypes from "prop-types";

const FieldList = ({ model, fieldsToShow, setter }) => {
  if (!fieldsToShow) {
    fieldsToShow = [...model.keySeq().toArray()];
  }
  return fieldsToShow.map((f) => {
    const rec = model.get(f);
    const Cpnt = rec.comp;
    return (
      <Cpnt
        sx={{ mb: 1 }}
        size="small"
        fullWidth
        key={f}
        name={f}
        variant="filled"
        label={rec.lbl}
        value={rec.value}
        helperText={rec.err ? rec.errmsg : " "}
        error={rec.err}
        onChange={(newVal) => setter(f, newVal)}
      />
    );
  });
};

FieldList.propTypes = {
  model: PropTypes.object.isRequired,
  fieldsToShow: PropTypes.array,
  setter: PropTypes.func.isRequired,
};

export default FieldList;
