import { useEffect, useState } from "react";
import { validateInvite } from "../../utility";
import { verifyTokenUpgrade } from "../../publicApi";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";

const LayoutUpgrade = () => {
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const [selectedServiceOption, setSelectedServiceOption] = useState(0);
  const [loadErr, setLoadErr] = useState("");
  const [inviteInfo, setInviteInfo] = useState(null);

  useEffect(() => {
    if (!validateInvite(inviteId)) {
      setInviteInfo(false);
      setLoadErr("Invite ID is invalid");
      return;
    }

    if (inviteInfo === null) {
      verifyTokenUpgrade(inviteId)
        .then((resp) => {
          if (resp.status !== "ok") {
            setInviteInfo(false);
            setLoadErr(resp.message);
            return;
          }
          setInviteInfo(resp.data);
          navigate("speed");
        })
        .catch((err) => {
          setInviteInfo(false);
          setLoadErr(err.message);
        });
    }
  }, [inviteId, inviteInfo, navigate]);

  if (null === inviteInfo) {
    return <Typography>Loading...</Typography>;
  }

  if (false === inviteInfo) {
    return <Typography>Error: {loadErr}</Typography>;
  }

  return (
    <Outlet
      context={{ inviteInfo, selectedServiceOption, setSelectedServiceOption }}
    />
  );
};

export default LayoutUpgrade;
