import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import App from "./Components/App";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const initialSessionToken = window.localStorage.getItem("session");

ReactDOM.render(
  <StrictMode>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <App initialSessionToken={initialSessionToken} />
      </BrowserRouter>
    </LocalizationProvider>
  </StrictMode>,
  document.getElementById("root")
);
