import { useEffect, useState } from "react";
import { Alert, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageAppBar from "../Shared/PageAppBar";
import PageHeading from "../Shared/PageHeading";
import PageBottomBtn from "../Shared/PageBottomBtn";
import { useOutletContext } from "react-router";

const PageSpeedUpgradeConfirm = () => {
  const navigate = useNavigate();
  const { api } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [callState, setCallState] = useState({
    inProgress: false,
    error: "",
    complete: false,
  });

  useEffect(() => {
    if (callState.error) return;
    if (callState.inProgress) return;
    if (callState.complete) return;
    const paymentIntentId = searchParams.get("payment_intent");
    setCallState({ inProgress: true, error: "" });
    api
      .finishPlanUpgrade(paymentIntentId)
      .then((resp) => {
        if (resp.status === "ok") {
          setCallState({ inProgress: false, error: "", complete: true });
          setSearchParams("", { replace: true });
          return;
        }
        setCallState({
          inProgress: false,
          error: resp.message,
          complete: true,
        });
        setSearchParams("", { replace: true });
      })
      .catch((err) => {
        setCallState({ inProgress: false, error: err.message, complete: true });
        setSearchParams("", { replace: true });
      });
  }, [
    callState.error,
    callState.inProgress,
    api,
    callState.complete,
    searchParams,
    setSearchParams,
  ]);

  if (callState.inProgress) {
    return <Typography>Loading...</Typography>;
  }

  if (callState.error) {
    return (
      <PageAppBar title="Upgrade Failed">
        <PageHeading>Upgrade Failed</PageHeading>
        <Alert severity="error">{callState.error}</Alert>
      </PageAppBar>
    );
  }

  return (
    <PageAppBar title="Upgrade Successful">
      <PageHeading>Upgrade Successful</PageHeading>
      <Typography sx={{ mb: 2 }}>
        Your network plan has been successfully upgraded.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Please allow up to 15 minutes for the changes to be pushed to your unit.
        During this time you may experience a brief interruption to your service
        while your devices is upgraded.
      </Typography>
      <Typography sx={{ mb: 2 }}>Enjoy your Hum internet!</Typography>
      <PageBottomBtn
        btnText="Back to Hum Portal"
        onClick={() => navigate("/home")}
      />
    </PageAppBar>
  );
};

export default PageSpeedUpgradeConfirm;
