import ActivatePageTitle from "./ActivatePageTitle";
import { Alert, Box, Typography } from "@mui/material";
import RegAppBar from "../../Shared/RegAppBar";
import { useEffect, useState } from "react";
import { startPaymentByToken, getStripePromise } from "../../../publicApi";
import { useOutletContext } from "react-router";
import { Elements } from "@stripe/react-stripe-js";
import UpgradeForm from "./UpgradeForm";

const PageActivatePayment = () => {
  const { inviteInfo, selectedServiceOption } = useOutletContext();
  const [errMsg, setErrMsg] = useState("");
  const [pintentData, setPintentData] = useState(null);

  useEffect(() => {
    startPaymentByToken(inviteInfo.reg_key, selectedServiceOption)
      .then((resp) => {
        if (resp.status !== "ok") {
          setErrMsg(resp.message);
          return;
        }
        setPintentData(resp.data);
      })
      .catch((err) => {
        setErrMsg(err.message);
      });
  }, [inviteInfo.reg_key, selectedServiceOption, setErrMsg, setPintentData]);

  return (
    <>
      <RegAppBar pageTitle="Step 6 of 6" backLink>
        <ActivatePageTitle>Payment Details</ActivatePageTitle>
        <Typography variant="body2">
          Enter your credit card information to get started!
        </Typography>
        <Typography variant="body2" sx={{ mt: 4 }}>
          Just like Hulu, Disney+ or Netflix, your upgrade Hum Internet
          connection bills monthly on the 1st of every month. You can cancel
          your upgraded service at any time and still enjoy your increased
          speeds until the start of the next billing cycle.
        </Typography>
        {errMsg && <Alert color="error">{errMsg}</Alert>}
        {pintentData && (
          <Box sx={{ mx: 2, my: 3 }}>
            <Elements
              stripe={getStripePromise()}
              options={{ clientSecret: pintentData.secret }}
            >
              <UpgradeForm />
            </Elements>
          </Box>
        )}
      </RegAppBar>
    </>
  );
};

export default PageActivatePayment;
