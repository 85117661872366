import { Box, Typography } from "@mui/material";
import { useOutletContext } from "react-router";
import ActivatePageTitle from "./ActivatePageTitle";
import FieldList from "../../Shared/FieldList";

const FORM_FIELDS = [
  "name_first",
  "name_last",
  "phone",
  "email",
  "password",
  "birthday",
];

const PageActivateBasics = () => {
  const [, wizardInfo, setFieldVal] = useOutletContext();

  return (
    <>
      <ActivatePageTitle>Let's get the basics down</ActivatePageTitle>
      <Typography variant="body2">
        This is how you'll access your Hum Account and Network going forward.
      </Typography>
      <Box sx={{ mb: 5 }} />
      <FieldList
        model={wizardInfo}
        fieldsToShow={FORM_FIELDS}
        setter={setFieldVal}
      />
    </>
  );
};

export default PageActivateBasics;
