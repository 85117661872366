import { forwardRef } from "react";
import { TextField } from "@mui/material";

const NTextField = forwardRef((props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    onChange={(e) => props.onChange(e.target.value)}
  />
));

export default NTextField;
