import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { formatPrice, formatSpeedTier } from "../../../utility";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useState } from "react";
import PageBottomBtn from "../../Shared/PageBottomBtn";
import { useNavigate } from "react-router-dom";

const PayForUpgradeControl = ({ apiData }) => {
  const navigate = useNavigate();

  const [pm, setPm] = useState(
    apiData.pms.length > 0 ? apiData.pms[0].id : null
  );
  const [confirmErr, setConfirmErr] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const stripeLoaded = stripe && elements;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pm === null) {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.protocol}//${window.location.host}/speed/upgrade/confirm`,
        },
      });
      if (result.error) {
        setConfirmErr(result.error.message);
      }
      return;
    }

    const result = await stripe.confirmCardPayment(apiData.payment.secret, {
      payment_method: pm,
    });
    if (result.error) {
      setConfirmErr(result.error.message);
    } else {
      navigate(
        `/speed/upgrade/confirm?payment_intent=${result.paymentIntent.id}`
      );
    }
  };

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title="Order Summary"
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <Box sx={{ display: "flex" }}>
            <Box component="span">{formatSpeedTier(apiData.targetSpeed)}</Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box component="span">{formatPrice(apiData.payment.amount, 2)}</Box>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Select A Payment Method"
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <List disablePadding>
            {apiData.pms.length > 0 && (
              <ListItem
                sx={{ p: 0 }}
                secondaryAction={
                  apiData.pms[0].id === pm ? (
                    <IconButton>
                      <RadioButtonCheckedIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setPm(apiData.pms[0].id)}>
                      <RadioButtonUncheckedIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemButton onClick={() => setPm(apiData.pms[0].id)}>
                  <ListItemText>
                    {apiData.pms[0].brand.toUpperCase()} {apiData.pms[0].last4}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )}
            <ListItem
              sx={{ p: 0 }}
              secondaryAction={
                pm ? (
                  <IconButton onClick={() => setPm(null)}>
                    <RadioButtonUncheckedIcon />
                  </IconButton>
                ) : (
                  <IconButton>
                    <RadioButtonCheckedIcon />
                  </IconButton>
                )
              }
            >
              <ListItemButton onClick={() => setPm(null)}>
                <ListItemText>Add a New Card</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </CardContent>
        {pm === null && (
          <CardContent>
            <PaymentElement />
          </CardContent>
        )}
      </Card>
      {confirmErr && <Alert severity="alert">{confirmErr}</Alert>}
      <PageBottomBtn
        btnText="Confirm Subscription"
        onClick={handleSubmit}
        disabled={!stripeLoaded}
      />
    </>
  );
};

export default PayForUpgradeControl;
