import { useState } from "react";
import { useOutletContext } from "react-router";
import { Typography } from "@mui/material";
import PageAppBar from "../Shared/PageAppBar";
import PageHeading from "../Shared/PageHeading";
import PageBottomBtn from "../Shared/PageBottomBtn";
import SpeedSelectionCardList from "../Shared/SpeedSelectionCardList";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utility";

const findCurTier = (serviceOptions, currentSpeedOrIndex) => {
  const tiers = { starter: 0, faster: 1, fastest: 2 };

  if (typeof currentSpeedOrIndex === "number") {
    let idx = 0;
    for (const [name, data] of Object.entries(serviceOptions)) {
      if (idx === currentSpeedOrIndex) {
        return {
          name: name,
          speed: data.speed,
          index: tiers[name],
          cost: data.cost,
        };
      }
      idx++;
    }

    return -1;
  }

  for (const [name, data] of Object.entries(serviceOptions)) {
    if (data.speed === currentSpeedOrIndex) {
      return {
        name: name,
        speed: data.speed,
        index: tiers[name],
        cost: data.cost,
      };
    }
  }
  return -1;
};

const whatIsChanging = (current, selected, planned) => {
  if (planned.speed === selected.speed) {
    return "same";
  }

  const curSpeed = parseInt(current.speed, 10);
  const selSpeed = parseInt(selected.speed, 10);
  if (curSpeed < selSpeed) {
    return "upgrade";
  }
  const plnSpeed = parseInt(planned.speed, 10);
  if (selSpeed < plnSpeed) {
    return "downgrade";
  }

  return "mind-change";
};

const PageSpeed = () => {
  const navigate = useNavigate();
  const { sessionData } = useOutletContext();
  const { api } = useOutletContext();

  const plannedSpeed =
    sessionData.occupancy.planned_speed ||
    sessionData.occupancy.prop_base_speed;

  const plnTier = findCurTier(sessionData.serviceOptions, plannedSpeed);
  const curTier = findCurTier(
    sessionData.serviceOptions,
    sessionData.occupancy.speed
  );

  const [curPlanPick, setCurPlanPick] = useState(plnTier.index);
  const [apiState, setApiState] = useState({ inProgress: false, error: "" });

  const selTier = findCurTier(sessionData.serviceOptions, curPlanPick);

  const action = whatIsChanging(curTier, selTier, plnTier);

  const handleStartSpeedChange = () => {
    if (action === "same") {
      navigate("/home", { state: { snackbar: `No change to plan` } });
      return;
    }

    if (action === "mind-change") {
      const cost = formatPrice(
        selTier.speed === sessionData.occupancy.prop_base_speed
          ? "0"
          : selTier.cost
      );
      const state = {
        snackbar: `You will be charged ${cost} at the beginning of next month`,
      };

      setApiState({ inProgress: true, error: "" });
      api
        .setPlanSpeed(selTier.speed)
        .then((resp) => {
          if (resp.status !== "ok") {
            setApiState({ inProgress: false, error: resp.message });
            return;
          }
          setApiState({ inProgress: false, error: "" });
          navigate("/home", { state });
        })
        .catch((err) => {
          setApiState({ inProgress: false, error: err.message });
        });

      return;
    }

    if (action === "downgrade") {
      navigate("/speed/downgrade", { state: { targetSpeed: selTier.speed } });
      return;
    }

    navigate("/speed/upgrade", { state: { targetSpeed: selTier.speed } });
  };

  return (
    <>
      <PageAppBar title="Change Speed">
        <PageHeading>Select your speed</PageHeading>
        <Typography sx={{ mb: 2 }}>
          Your unit already includes a blazing fast fiber Internet connection
          powered by Hum as part of your amenities! But if you'd like even
          faster speeds, we have you covered! Please select a service plan to
          get connected:
        </Typography>
        <SpeedSelectionCardList
          selected={curPlanPick}
          currentSpeed={sessionData.occupancy.speed}
          plannedSpeed={plannedSpeed}
          onChange={setCurPlanPick}
          serviceOptions={sessionData.serviceOptions}
        />
      </PageAppBar>
      <PageBottomBtn
        btnText={action === "same" ? "Keep Speed" : "Change Speed"}
        onClick={handleStartSpeedChange}
        disabled={apiState.inProgress}
      />
    </>
  );
};

export default PageSpeed;
