import ActivatePageTitle from "./ActivatePageTitle";
import { Typography } from "@mui/material";
import FieldList from "../../Shared/FieldList";
import { useOutletContext } from "react-router";

const FORM_FIELDS = ["ssid", "wifi_key"];

const PageActivateNetwork = () => {
  const [, wizardInfo, setFieldVal] = useOutletContext();

  return (
    <>
      <ActivatePageTitle>Let's Setup Your Network</ActivatePageTitle>{" "}
      <Typography variant="body2">
        Last step! Let's setup a name for your Wireless Network (SSID) and a
        password you'll be able to remember for your home network.
      </Typography>
      <FieldList
        model={wizardInfo}
        fieldsToShow={FORM_FIELDS}
        setter={setFieldVal}
      />
    </>
  );
};

export default PageActivateNetwork;
