import { getDaysInMonth } from "date-fns";

const PTN_INVITE = new RegExp("^[0-9a-f]{32}$");

export const validateInvite = (ipt) => PTN_INVITE.test(ipt);

export const formatPhone = (ipt) => {
  const areaCode = ipt.slice(0, 3);
  const exchange = ipt.slice(3, 6);
  const numb = ipt.slice(6);

  return `(${areaCode}) ${exchange}-${numb}`;
};
const PTN_PHONE = new RegExp("^\\d{10}$");
const PTN_EMAIL = new RegExp("^[^@ ]+@[^@ ]+$");
const PTN_PASSWORD = new RegExp("^[A-Za-z0-9!@#$%^&*()_+=\\[\\]{}|'-]{8,}$");
const PTN_LOWER = new RegExp("[a-z]");
const PTN_UPPER = new RegExp("[A-Z]");
const PTN_NUMBER = new RegExp("[0-9]");
const PTN_SPECIAL = new RegExp("[!@#$%^&*()_+=\\[\\]{}|'-]");

const PWD_ERR =
  " must be at least 8 characters and needs to have at least two of the following: uppercase character, lowercase character, number or symbol. Valid symbols are !@#%^&*()_+=[]{}|'-";

export const validCustomerPassword = (val, lbl) => {
  if (!PTN_PASSWORD.test(val)) {
    return `${lbl}${PWD_ERR}`;
  }

  let lower = PTN_LOWER.test(val);
  let upper = PTN_UPPER.test(val);
  let number = PTN_NUMBER.test(val);
  let special = PTN_SPECIAL.test(val);
  const classes = +lower + upper + number + special;
  if (classes >= 2) {
    return "";
  }

  return `${lbl}${PWD_ERR}`;
};

export const validRequiredDate = (val, lbl) => {
  if (val === null) {
    return `${lbl} must be specified`;
  }

  if (val.toString() === "Invalid Date") {
    return `${lbl} must be a valid date`;
  }

  if (val.getFullYear() < 1910) {
    return `${lbl} must be a reasonable date`;
  }

  return "";
};

export const validRequired = (val, lbl) => {
  if (val.length > 0) {
    return "";
  }

  return `${lbl} must be specified`;
};

export const validPhone = (val, lbl) => {
  if (val === "") {
    return `${lbl} must be specified`;
  }

  if (!PTN_PHONE.test(val)) {
    return `${lbl} is invalid`;
  }

  return "";
};

export const validEmail = (val, lbl) => {
  if (val === "") {
    return `${lbl} must be specified`;
  }

  if (!PTN_EMAIL.test(val)) {
    return `${lbl} is invalid`;
  }

  return "";
};

export const formatPrice = (priceInCents, digits = 0) => {
  if (typeof priceInCents !== "number") {
    return "";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  const priceInDollers = priceInCents / 100;
  return formatter.format(priceInDollers);
};

export const formatSpeedTier = (speedTier) => {
  if (typeof speedTier !== "number") {
    speedTier = parseInt(speedTier, 10);
    if (isNaN(speedTier)) {
      return "";
    }
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
  });
  return `${formatter.format(speedTier)}Mbps`;
};

export const validWifiSSID = (ssid, lbl) => {
  if (ssid === "") {
    return `${lbl} must be specified`;
  }

  if (ssid.length < 2) {
    return `${lbl} must be at least 2 characters`;
  }

  return "";
};

export const validWifiKey = (wifiKey, lbl) => {
  if (wifiKey === "") {
    return `${lbl} must be specified`;
  }

  if (wifiKey.length < 8) {
    return `${lbl} must be at least 8 characters`;
  }

  return "";
};

export const dateFirstOfNextMonthShortFormat = () => {
  const now = new Date();
  now.setMonth(now.getMonth() + 1);
  now.setDate(1);
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const year = now.getFullYear().toString().substring(2);
  const month = (now.getMonth() + 1).toString();
  const day = now.getDate().toString();

  return `${month}/${day}/${year}`;
};

export const dateObjToDateStr = (dateObj) => {
  if (!dateObj) {
    return dateObj;
  }

  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const dateStrToDateObj = (dateStr) => {
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(5, 7), 10);
  const day = parseInt(dateStr.substring(8), 10);
  return new Date(year, month - 1, day);
};

/**
 * Given a monthly rate and date, return the prorated monthlyAmount
 *
 * Let's say we charge $10.00 per month for our service. On April 16, 2022, someone wants to
 * subscribe for the service. We would want to prorate the charge for 15 of the 30 days in
 * April resulting in $5.00 and then charge $10.00 per month starting May 1, 2022. This
 * function facilitates that calculation.
 *
 * const proratedAmount = upgradeProrate(new Date(2022, 3, 16), 1000);
 * console.log(proratedAmount); // results in 500.
 *
 * @param {Date} forDate
 * @param {number} monthlyAmount
 * @returns {number}
 */
export const upgradeProrate = (forDate, monthlyAmount) => {
  const d = new Date(forDate);
  const totalDays = getDaysInMonth(d);
  const curDay = d.getDate();
  const prorate = (totalDays - curDay + 1) / totalDays;
  return Math.floor(monthlyAmount * prorate);
};
