import { Map } from "immutable";
import { Snackbar, Typography, Link, Alert } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { updateFldRec, FldRecord, isValid } from "../../FldRecord";
import PageAppBar from "../Shared/PageAppBar";
import PageHeading from "../Shared/PageHeading";
import NTextField from "../FormControls/NTextField";
import {
  dateObjToDateStr,
  dateStrToDateObj,
  validEmail,
  validPhone,
  validRequired,
  validRequiredDate,
} from "../../utility";
import NDateField from "../FormControls/NDateField";
import FieldList from "../Shared/FieldList";
import { useState } from "react";
import { useOutletContext } from "react-router";
import { useNavigate } from "react-router-dom";
import PageBottomBtn from "../Shared/PageBottomBtn";

const FORM_MODEL = {
  name_first: FldRecord({
    lbl: "First Name",
    comp: NTextField,
    valid: validRequired,
  }),
  name_last: FldRecord({
    lbl: "Last Name",
    comp: NTextField,
    valid: validRequired,
  }),
  phone: FldRecord({
    lbl: "Phone Number",
    comp: NTextField,
    valid: validPhone,
    norm: (x) => x.replaceAll(/\D/g, ""),
  }),
  email: FldRecord({
    lbl: "Email Address",
    comp: NTextField,
    valid: validEmail,
  }),
  birthday: FldRecord({
    lbl: "Birthday",
    comp: NDateField,
    value: null,
    valid: validRequiredDate,
  }),
};

const initialFormData = (formModel, sessionData) => {
  const newMod = formModel.withMutations((m) => {
    const bday = dateStrToDateObj(sessionData.birthday);
    return m
      .set(
        "name_first",
        updateFldRec(m.get("name_first"), sessionData.name_first)
      )
      .set("name_last", updateFldRec(m.get("name_last"), sessionData.name_last))
      .set("email", updateFldRec(m.get("email"), sessionData.email))
      .set("phone", updateFldRec(m.get("phone"), sessionData.phone))
      .set("birthday", updateFldRec(m.get("birthday"), bday));
  });
  return newMod;
};

const PageAccount = () => {
  const navigate = useNavigate();
  const { sessionData, api } = useOutletContext();
  const [snackBarOpen, setSnackBarOpen] = useState("");
  const [formDat, setFormDat] = useState(
    initialFormData(Map(FORM_MODEL), sessionData)
  );

  const handleSBClose = () => setSnackBarOpen(false);

  const setFieldVal = (field, val) =>
    setFormDat((prev) => {
      const fld = prev.get(field);
      const newFld = updateFldRec(fld, val);
      return prev.set(field, newFld);
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const toSubmit = formDat.map((r) => r.clean).toJS();
    toSubmit.birthday = dateObjToDateStr(toSubmit.birthday);
    api
      .updateAccount(toSubmit)
      .then((resp) => {
        navigate("/home", { state: { snackbar: "Account updated!" } });
      })
      .catch((err) => {
        setSnackBarOpen(err.message);
      });
  };

  const isValidForm = isValid(formDat);

  return (
    <>
      <PageAppBar title="Edit My Account">
        <PageHeading>Edit My Account</PageHeading>
        <Typography sx={{ mb: 2 }}>
          Update your contact information for your Hum Service.
        </Typography>
        <form onSubmit={handleSubmit}>
          <FieldList model={formDat} setter={setFieldVal} />
        </form>
        <Link underline="none" component={RouterLink} to="/change-password">
          Change Password
        </Link>
      </PageAppBar>
      <PageBottomBtn
        btnText="Confirm"
        onClick={handleSubmit}
        disabled={!isValidForm}
      />
      <Snackbar
        open={!!snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSBClose}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert onClose={handleSBClose} severity="error">
          {snackBarOpen}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PageAccount;
