import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Map } from "immutable";
import { updateFldRec, FldRecord } from "../../FldRecord";
import { Typography } from "@mui/material";
import { verifyToken } from "../../publicApi";
import {
  validateInvite,
  validCustomerPassword,
  validEmail,
  validPhone,
  validRequired,
  validRequiredDate,
  validWifiKey,
  validWifiSSID,
} from "../../utility";
import NPasswordField from "../FormControls/NPasswordField";
import NTextField from "../FormControls/NTextField";
import NDateField from "../FormControls/NDateField";
import RegAppBar from "../Shared/RegAppBar";
import RegBottomBtn from "../Shared/RegBottomBtn";

const LAYOUT_OPTS = {
  welcome: {
    backLink: false,
    pageTitle: "Setup",
    nextLinkText: "Get Started",
    nextLinkTarget: "basics",
  },
  basics: {
    backLink: true,
    pageTitle: "Step 1 of 6",
    nextLinkText: "Next",
    nextLinkTarget: "location",
  },
  location: {
    backLink: true,
    pageTitle: "Step 2 of 6",
    nextLinkText: "Next",
    nextLinkTarget: "network",
  },
  network: {
    backLink: true,
    pageTitle: "Step 3 of 6",
    nextLinkText: "Next",
    nextLinkTarget: "netsetup",
  },
  netsetup: {
    backLink: false,
    pageTitle: "Step 4 of 6",
  },
  help: {
    backLink: true,
    pageTitle: "Support",
  },
};

const localPathFromLocation = (location) => {
  const path = location.pathname;
  const slashLoc = path.lastIndexOf("/");
  return path.substring(slashLoc + 1);
};

const FORM_MODEL = {
  name_first: FldRecord({
    lbl: "First Name",
    comp: NTextField,
    valid: validRequired,
  }),
  name_last: FldRecord({
    lbl: "Last Name",
    comp: NTextField,
    valid: validRequired,
  }),
  phone: FldRecord({
    lbl: "Phone Number",
    comp: NTextField,
    valid: validPhone,
    norm: (x) => x.replaceAll(/\D/g, ""),
  }),
  email: FldRecord({
    lbl: "Email Address",
    comp: NTextField,
    valid: validEmail,
  }),
  password: FldRecord({
    lbl: "Password",
    comp: NPasswordField,
    valid: validCustomerPassword,
  }),
  birthday: FldRecord({
    lbl: "Birthday",
    comp: NDateField,
    value: null,
    valid: validRequiredDate,
  }),
  ssid: FldRecord({
    lbl: "Wireless Network Name (SSID)",
    comp: NTextField,
    valid: validWifiSSID,
  }),
  wifi_key: FldRecord({
    lbl: "WiFi Password",
    comp: NPasswordField,
    valid: validWifiKey,
  }),
};

const isNextBtnDisabled = (wizardInfo, pathKey) => {
  if (
    pathKey === "basics" &&
    (wizardInfo.get("password").value === "" ||
      wizardInfo.get("birthday").value === null)
  ) {
    return true;
  }

  if (pathKey === "payment") {
    return true;
  }

  if (
    pathKey === "network" &&
    (wizardInfo.get("wifi_key").value === "" ||
      wizardInfo.get("ssid").value === "")
  ) {
    return true;
  }

  return wizardInfo.reduce((acc, fldRec) => acc || fldRec.err, false);
};

const LayoutActivate = () => {
  const location = useLocation();
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const [loadErr, setLoadErr] = useState("");
  const [inviteInfo, setInviteInfo] = useState(null);
  const [intercomStarted, setIntercomStarted] = useState(false);
  const [wizardInfo, setWizardInfo] = useState(Map(FORM_MODEL));
  const [nextBtn, setNextBtn] = useState({ text: null, target: null });

  const overrideNextBtn = (text, target) => setNextBtn({ text, target });
  const clearNextBtnOvr = () => setNextBtn({ text: null, target: null });

  const pathKey = localPathFromLocation(location);
  const pageRenderOpts = LAYOUT_OPTS[pathKey] || {};
  const nextDisabled = isNextBtnDisabled(wizardInfo, pathKey);
  const nextLinkText = nextBtn.text || pageRenderOpts.nextLinkText;
  const nextLinkTarget = nextBtn.target || pageRenderOpts.nextLinkTarget;

  const setFieldVal = (field, val) =>
    setWizardInfo((prev) => {
      const fld = prev.get(field);
      const newFld = updateFldRec(fld, val);
      return prev.set(field, newFld);
    });

  useEffect(() => {
    if (!validateInvite(inviteId)) {
      setInviteInfo(false);
      setLoadErr("Invite ID is invalid");
      return;
    }

    if (inviteInfo === null) {
      verifyToken(inviteId)
        .then((resp) => {
          if (resp.status === "redirect") {
            navigate(resp.data);
            return;
          }
          if (resp.status !== "ok") {
            setInviteInfo(false);
            setLoadErr(resp.message);
            return;
          }
          setInviteInfo(resp.data);
          setWizardInfo((prev) => {
            return prev.withMutations((o) =>
              o
                .set(
                  "name_first",
                  updateFldRec(o.get("name_first"), resp.data.res_name_first)
                )
                .set(
                  "name_last",
                  updateFldRec(o.get("name_last"), resp.data.res_name_last)
                )
                .set("phone", updateFldRec(o.get("phone"), resp.data.res_phone))
                .set("email", updateFldRec(o.get("email"), resp.data.res_email))
            );
          });
          navigate("welcome");
        })
        .catch((err) => {
          setInviteInfo(false);
          setLoadErr(err.message);
        });
    } else {
      if (!intercomStarted) {
        window.Intercom("boot", {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          email: inviteInfo.res_email,
          name: `${inviteInfo.res_name_first} ${inviteInfo.res_name_last}`,
          user_id: inviteInfo.intercom_id,
        });
        setIntercomStarted(true);
      }
    }
  }, [inviteId, inviteInfo, navigate, intercomStarted]);

  if (null === inviteInfo) {
    return <Typography>Loading...</Typography>;
  }

  if (false === inviteInfo) {
    return <Typography>Error: {loadErr}</Typography>;
  }

  return (
    <>
      <RegAppBar
        pageTitle={pageRenderOpts.pageTitle}
        backLink={pageRenderOpts.backLink}
      >
        <Outlet
          context={[inviteInfo, wizardInfo, setFieldVal, overrideNextBtn]}
        />
      </RegAppBar>
      {nextLinkText && (
        <RegBottomBtn
          btnText={nextLinkText}
          disabled={nextDisabled}
          onClick={() => {
            clearNextBtnOvr();
            navigate(nextLinkTarget);
          }}
        />
      )}
    </>
  );
};

export default LayoutActivate;
