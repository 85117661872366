import ActivatePageTitle from "./ActivatePageTitle";
import { Box, Typography } from "@mui/material";
import SpeedSelectionCardList from "../../Shared/SpeedSelectionCardList";
import { useState } from "react";
import { useOutletContext } from "react-router";
import RegAppBar from "../../Shared/RegAppBar";
import RegBottomBtn from "../../Shared/RegBottomBtn";
import { useNavigate } from "react-router-dom";

const PageActivateSpeed = () => {
  const navigate = useNavigate();
  const { inviteInfo, setSelectedServiceOption, selectedServiceOption } =
    useOutletContext();
  const [btnOpts, setBtnOpts] = useState({ text: "Next", link: "../complete" });

  const handleSetPlan = (idx) => {
    setSelectedServiceOption(idx);
    idx !== 0
      ? setBtnOpts({ text: "Upgrade", link: "../payment" })
      : setBtnOpts({ text: "Next", link: "../complete" });
  };

  return (
    <>
      <RegAppBar pageTitle="Step 5 of 6">
        <ActivatePageTitle>Select your speed</ActivatePageTitle>
        <Typography variant="body2">
          Your unit already includes a blazing fast fiber internet connection
          powered by Hum as part of your amenities! But if you'd like even
          faster speeds, we have you covered! Please select a service plan to
          get connected:
        </Typography>
        <Box sx={{ mb: 5 }} />
        <SpeedSelectionCardList
          selected={selectedServiceOption}
          onChange={handleSetPlan}
          serviceOptions={inviteInfo.serviceOptions}
        />
      </RegAppBar>
      <RegBottomBtn
        btnText={btnOpts.text}
        onClick={() => navigate(btnOpts.link)}
      />
    </>
  );
};

export default PageActivateSpeed;
