import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SpeedIcon from "@mui/icons-material/Speed";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../../assets/logo-main.svg";

const NavListItem = ({ Icon, text, link }) => {
  const navigate = useNavigate();
  return (
    <ListItem>
      <ListItemButton onClick={() => navigate(link)}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

const PageAppBar = ({ title, children }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton
            onClick={() => setDrawerOpen(true)}
            color="inherit"
            sx={{ mr: 2 }}
          >
            <MenuIcon color="inherit" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          <IconButton color="inherit" onClick={() => navigate("/help")}>
            <HelpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          textAlign: "center",
          bgcolor: grey[200],
          minHeight: "100vh",
          px: 2,
        }}
      >
        <Toolbar />
        {children}
      </Box>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 330, py: 6, textAlign: "center" }}>
          <img src={logo} width="142" alt="Hum Logo" />
        </Box>
        <Divider />
        <List disablePadding>
          <NavListItem Icon={HomeIcon} text="Home" link="/home" />
          <NavListItem Icon={SpeedIcon} text="Change Speeds" link="/speed" />
          <NavListItem
            Icon={AccountCircleIcon}
            text="My Account"
            link="/account"
          />
          <NavListItem Icon={HelpIcon} text="Get Help" link="/help" />
          <Divider />
          <NavListItem Icon={LogoutIcon} text="Sign Out" link="/logout" />
        </List>
      </Drawer>
    </>
  );
};

export default PageAppBar;
