import { useEffect, useReducer } from "react";
import { Record } from "immutable";
import { Alert, Typography } from "@mui/material";
import PageHeading from "../Shared/PageHeading";
import PageAppBar from "../Shared/PageAppBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router";
import PayForUpgradeControl from "./PageSpeedUpgrade/PayForUpgradeControl";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePromise } from "../../publicApi";

const mkPageState = Record({
  callInProgress: false,
  callError: "",
  apiData: null,
});

const init = () => mkPageState();

const actions = {
  API_START: (state) => state.set("callInProgress", true),
  API_START_SUCCESS: (state, apiData) =>
    state.withMutations((r) => {
      return r
        .set("callInProgress", false)
        .set("callError", "")
        .set("apiData", apiData);
    }),
  API_START_ERROR: (state, errorMessage) =>
    state.withMutations((r) => {
      return r.set("callInProgress", false).set("callError", errorMessage);
    }),
};

const reducer = (state, action) => {
  const type = action.type;
  if (!type) {
    throw new Error("No type specified on dispatch?");
  }

  const actFn = actions[type];
  if (!actFn) {
    throw new Error(`Type of ${type} not recognized`);
  }

  const extraArgs = action.extraArgs || [];
  return actFn(state, ...extraArgs);
};

const PageSpeedUpgrade = () => {
  const [state, dispatch] = useReducer(reducer, undefined, init);
  const navigate = useNavigate();
  const { api } = useOutletContext();

  // if we don't have a targetSpeed, redirect out of here
  // todo maybe check the target speed is valid?
  const location = useLocation();
  const targetSpeed = location?.state?.targetSpeed;
  useEffect(
    () => targetSpeed || navigate("/speed", { replace: true }),
    [targetSpeed, navigate]
  );

  useEffect(() => {
    if (state.apiData !== null) return;
    dispatch({ type: "API_START" });
    api
      .startPlanUpgrade(targetSpeed)
      .then((resp) => {
        if (resp.status !== "ok") {
          dispatch({ type: "API_START_ERROR", extraArgs: [resp.message] });
          return;
        }

        dispatch({ type: "API_START_SUCCESS", extraArgs: [resp.data] });
      })
      .catch((err) => {
        dispatch({ type: "API_START_ERROR", extraArgs: [err.message] });
      });
  }, [state.apiData, api, targetSpeed]);

  return (
    <PageAppBar title="Payment Details">
      <PageHeading>Payment Details</PageHeading>
      <Typography sx={{ mb: 2 }}>
        Enter your credit card information to get started!
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Just like Hulu, Disney+ or Netflix, your upgraded Hum Internet
        connection bills monthly on the 1st of every month. You can cancel your
        upgraded service at any time during and still enjoy your increased
        speeds till the start of the net billing cycle.
      </Typography>

      {state.callError && (
        <Alert sx={{ mb: 2 }} severity="error" variant="contained">
          {state.callError}
        </Alert>
      )}

      {state.apiData && (
        <Elements
          stripe={getStripePromise()}
          options={{ clientSecret: state.apiData.payment.secret }}
        >
          <PayForUpgradeControl apiData={state.apiData} />
        </Elements>
      )}
    </PageAppBar>
  );
};

export default PageSpeedUpgrade;
