import { useEffect } from "react";
import { useOutletContext } from "react-router";
import { Typography } from "@mui/material";

const PageLogout = () => {
  const { setSessionToken } = useOutletContext();

  useEffect(() => setSessionToken(""), [setSessionToken]);

  return <Typography>Logging you out...</Typography>;
};

export default PageLogout;
