import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { login } from "../../../publicApi";
import logoMain from "../../../assets/logo-main.svg";
import { useNavigate } from "react-router-dom";

const GeneralError = ({ msg }) => {
  if (!msg) {
    return null;
  }

  return (
    <Alert sx={{ mt: 2 }} color="error">
      {msg}
    </Alert>
  );
};

const LoginForm = ({ setSessionToken }) => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);

  const [errors, setErrors] = useState({ errors: {}, message: "" });
  const clearErrors = () => setErrors({ errors: {}, message: "" });
  const handleSetErrors = (errStruct, errGeneral) =>
    setErrors({ errors: errStruct || {}, message: errGeneral });

  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => setEmail(e.target.value);

  const [password, setPassword] = useState("");
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    clearErrors();
    setBusy(true);
    login(email, password)
      .then((resp) => {
        if (resp.status === "ok") {
          setSessionToken(resp.data);
          navigate("/home");
          return;
        }
        setBusy(false);
        if (resp.status === "validation") {
          handleSetErrors(resp.errors, resp.message);
          return;
        }
        handleSetErrors({}, resp.message);
      })
      .catch((err) => {
        setBusy(false);
        handleSetErrors({}, err.message);
      });
  };

  return (
    <Paper
      sx={{
        textAlign: "center",
        mx: "auto",
        my: 5,
        p: 3,
        maxWidth: "450px",
        height: "575px",
      }}
    >
      <Box sx={{ my: 3, pl: "23px" }}>
        <img
          src={logoMain}
          alt="Hum logo"
          style={{
            "background-color": "white",
            "mask-image": `url(${logoMain})`,
            "-webkit-mask-image": `url(${logoMain})`,
            "mask-repeat": "no-repeat",
          }}
        />
      </Box>
      <Typography variant="h4">Log In to Hum</Typography>
      <Typography>Enter your email and password below</Typography>
      <GeneralError msg={errors.message} />
      <Box sx={{ mt: 4 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            value={email}
            onChange={handleEmailChange}
            name="email"
            variant="standard"
            label="Email"
            error={!!errors.errors.email}
            helperText={errors.errors.email || " "}
            fullWidth
          />
          <TextField
            value={password}
            onChange={handlePasswordChange}
            type="password"
            name="password"
            variant="standard"
            label="Password"
            error={!!errors.errors.password}
            helperText={errors.errors.password || " "}
            fullWidth
          />
          <Button
            disabled={busy}
            type="submit"
            sx={{ mt: 4 }}
            variant="contained"
            fullWidth
          >
            Log In
          </Button>
        </form>
      </Box>
    </Paper>
  );
};

export default LoginForm;
