import { Button, Snackbar } from "@mui/material";

const AUTO_HIDE = 6000;

const SuccessSnackbar = ({ message, open, onClose }) => {
  return (
    <Snackbar
      message={message}
      open={open}
      onClose={onClose}
      autoHideDuration={AUTO_HIDE}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      action={<Button onClick={onClose}>Dismiss</Button>}
    />
  );
};

export default SuccessSnackbar;
