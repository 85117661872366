import { useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import RegAppBar from "../../Shared/RegAppBar";
import ActivatePageTitle from "./ActivatePageTitle";
import { useParams, useSearchParams } from "react-router-dom";
import { finishPaymentByToken } from "../../../publicApi";

const PageActivateComplete = () => {
  const { inviteId } = useParams();
  const [completeCall, setCompleteCall] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!completeCall) {
      finishPaymentByToken(inviteId, searchParams.get("payment_intent"))
        .then(() => {
          setCompleteCall(true);
          setSearchParams("", { replace: true });
        })
        .catch((err) => {
          setCompleteCall(err.message);
          setSearchParams("", { replace: true });
        });
    }
  }, [completeCall, inviteId, searchParams, setCompleteCall, setSearchParams]);

  return (
    <RegAppBar pageTitle="Setup Complete" backLink={false}>
      <ActivatePageTitle>You're all set!</ActivatePageTitle>
      <Box sx={{ mt: 4 }} />
      {completeCall === true && (
        <Typography variant="body2">Enjoy your Hum internet!</Typography>
      )}
      {completeCall === false && <CircularProgress />}
      {typeof completeCall === "string" && (
        <Alert color="error">{completeCall}</Alert>
      )}
    </RegAppBar>
  );
};

export default PageActivateComplete;
