import { Box, Button, Typography } from "@mui/material";
import { useOutletContext } from "react-router";
import ActivatePageTitle from "./ActivatePageTitle";

const PageActivateLocation = () => {
  const [inviteInfo] = useOutletContext();

  return (
    <>
      <ActivatePageTitle>Are we in the right place?</ActivatePageTitle>
      <Typography variant="body2">
        We've worked with your Property Manager to setup your equipment.
      </Typography>
      <Typography variant="body2" sx={{ mt: 4 }}>
        Please ensure your new address is correctly listed below:
      </Typography>
      <Box sx={{ mb: 5 }} />
      <Box sx={{ fontSize: "larger" }}>
        <Typography>{inviteInfo.bld.addr_street},</Typography>
        <Typography>Apt {inviteInfo.unit_num}</Typography>
        <Typography>
          {inviteInfo.bld.addr_city}, {inviteInfo.bld.addr_state}{" "}
          {inviteInfo.bld.addr_zip}
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button sx={{ textTransform: "none" }} color="error">
          This Is Not My Address
        </Button>
      </Box>
    </>
  );
};

export default PageActivateLocation;
