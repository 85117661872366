import { AppBar, Button, Toolbar } from "@mui/material";

const RegBottomBtn = ({ onClick, disabled, btnText }) => {
  return (
    <AppBar position="fixed" sx={{ top: "auto", bottom: 0, bgcolor: "white" }}>
      <Toolbar>
        <Button
          disabled={disabled}
          variant="contained"
          fullWidth
          onClick={onClick}
        >
          {btnText}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default RegBottomBtn;
