import { Box, Typography } from "@mui/material";
import ActivatePageTitle from "./ActivatePageTitle";
import logo from "../../../assets/hum-logo.svg";

const PageActivateWelcome = () => {
  return (
    <>
      <Typography variant="caption">POWERED BY</Typography>
      <Box sx={{ mb: 5 }}>
        <img src={logo} alt="Hum Logo" width="150" />
      </Box>
      <ActivatePageTitle>Welcome to your home!</ActivatePageTitle>
      <Typography>
        Let's get started configuring your in-home wireless network.
      </Typography>
    </>
  );
};

export default PageActivateWelcome;
