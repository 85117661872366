import { Record } from "immutable";

export const FldRecord = Record({
  lbl: "",
  comp: null,
  value: "",
  clean: "",
  err: false,
  errmsg: "",
  norm: (x) => x,
  valid: () => "",
});

export const updateFldRec = (fldRec, newVal) => {
  return fldRec.withMutations((f) => {
    const newClean = f.norm(newVal);
    const newErrMsg = f.valid(newClean, f.lbl);
    const newErr = !!newErrMsg;
    return f
      .set("value", newVal)
      .set("clean", newClean)
      .set("err", newErr)
      .set("errmsg", newErrMsg);
  });
};

export const isValid = (formState) =>
  formState.reduce((prev, cur) => {
    if (!prev) return false;
    return !cur.err;
  }, true);
