import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckIcon from "@mui/icons-material/Check";

import { grey } from "@mui/material/colors";

const MarketingChip = ({ label }) => (
  <Chip
    sx={{ mt: 1, mr: 1 }}
    variant="outlined"
    label={label}
    icon={<CheckIcon />}
  />
);

const ChipList = ({ chipList }) =>
  chipList.map((l) => <MarketingChip key={l} label={l} />);

const SpeedSelectionCard = ({
  onClick,
  title,
  subtitle,
  icon,
  selected,
  bodyText,
  chipList,
  invertIcon,
}) => {
  const invIcon = !!invertIcon;

  return (
    <Card sx={{ textAlign: "left", mb: 2 }} variant="outlined" elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <Box
              sx={{
                bgcolor: invIcon ? "primary.main" : grey[100],
                borderRadius: 2,
                height: "48px",
                width: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {icon}
            </Box>
          }
          titleTypographyProps={{
            sx: { fontWeight: "bold" },
            variant: "body1",
          }}
          title={title}
          subheader={subtitle}
          action={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "56px",
                width: "32px",
              }}
            >
              {selected ? (
                <RadioButtonCheckedIcon color="primary" />
              ) : (
                <RadioButtonUncheckedIcon color="action" />
              )}
            </Box>
          }
        />
      </CardActionArea>
      <Collapse orientation="vertical" in={selected}>
        <CardContent>
          <Typography variant="body2" sx={{ mb: 3 }}>
            {bodyText}
          </Typography>
          <ChipList chipList={chipList} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SpeedSelectionCard;
