import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { forwardRef } from "react";

const NDateField = forwardRef(
  ({ value, label, onChange, error, helperText, ...rest }, ref) => {
    return (
      <DesktopDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        onChange={onChange}
        value={value}
        inputRef={ref}
        {...rest}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="filled"
            size="small"
            error={error}
            helperText={helperText}
            sx={{ mb: 1 }}
          />
        )}
      />
    );
  }
);

export default NDateField;
