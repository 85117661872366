import ky from "ky";

const genApiUrl = (pathParts) => {
  const base = process.env.REACT_APP_API_ROOT;
  const parts = pathParts.join("/");
  return `${base}${parts}`;
};

const mkOpts = (method, token, payload) => {
  const opts = {
    method,
    headers: { Authorization: `Bearer ${token}` },
  };

  if (payload) {
    opts.headers["Content-Type"] = "application/json";
    opts.json = payload;
  }

  return opts;
};

const makeRequest = async (url, opts, onNewSession, onSessionFail) => {
  try {
    const resp = await ky(url, opts);
    const newSessionToken = resp.headers.get("x-session");
    onNewSession(newSessionToken);
    return resp.json();
  } catch (err) {
    if (err.response.status === 401) {
      onSessionFail();
      return;
    }
    throw err;
  }
};

export const resiApi = (token, onNewSession, onSessionFail) => {
  const updateAccount = (payload) => {
    const url = genApiUrl(["resi", "account"]);
    const opts = mkOpts("POST", token, payload);
    return makeRequest(url, opts, onNewSession, onSessionFail);
  };

  const triggerPasswordReset = () => {
    const url = genApiUrl(["resi", "password"]);
    const opts = mkOpts("POST", token);
    return makeRequest(url, opts, onNewSession, onSessionFail);
  };

  const setPlanSpeed = (targetSpeed) => {
    const payload = { targetSpeed };
    const url = genApiUrl(["resi", "plan-change"]);
    const opts = mkOpts("POST", token, payload);
    return makeRequest(url, opts, onNewSession, onSessionFail);
  };

  const startPlanUpgrade = (targetSpeed) => {
    const url = genApiUrl(["resi", "plan-upgrade"]);
    const opts = mkOpts("POST", token, { targetSpeed });
    return makeRequest(url, opts, onNewSession, onSessionFail);
  };

  const finishPlanUpgrade = (paymentIntentId) => {
    const url = genApiUrl(["resi", "plan-upgrade"]);
    const opts = mkOpts("PUT", token, { paymentIntentId });
    return makeRequest(url, opts, onNewSession, onSessionFail);
  };

  return {
    updateAccount,
    triggerPasswordReset,
    setPlanSpeed,
    startPlanUpgrade,
    finishPlanUpgrade,
  };
};
