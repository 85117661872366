import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpIcon from "@mui/icons-material/Help";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const RegAppBar = ({ children, backLink, pageTitle }) => {
  const navigate = useNavigate();
  return (
    <>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            disabled={!backLink}
            onClick={() => navigate(-1)}
          >
            {backLink && <ArrowBackIcon />}
          </IconButton>
          <Box>
            <Typography>{pageTitle}</Typography>
          </Box>
          <IconButton
            color="inherit"
            onClick={() => navigate("/activate/help")}
          >
            <HelpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: grey[200],
          pt: 3,
          px: 2,
          textAlign: "center",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
};

export default RegAppBar;
