import ActivatePageTitle from "./ActivatePageTitle";
import { List, Typography } from "@mui/material";
import IconMenuItem from "../../Shared/IconMenuItem";
import ChatIcon from "@mui/icons-material/Chat";
import EmailIcon from "@mui/icons-material/Email";
import RegAppBar from "../../Shared/RegAppBar";
import { useNavigate } from "react-router-dom";

const PageActivateHelp = () => {
  const navigate = useNavigate();

  return (
    <RegAppBar backLink={() => navigate(-1)} pageTitle="Support">
      <ActivatePageTitle>How can we help?</ActivatePageTitle>
      <Typography variant="body2">
        Select an option below to talk to someone.
      </Typography>
      <List>
        <IconMenuItem
          Icon={ChatIcon}
          iconColor="primary"
          primary="Chat Us"
          secondary="Chat with us in real time"
          onClick={() => window.Intercom("show")}
        />
        <IconMenuItem
          Icon={ChatIcon}
          iconColor="secondary"
          primary="Text Us"
          secondary="Send us a message through text"
          link="sms:13137105379"
        />
        <IconMenuItem
          Icon={EmailIcon}
          iconColor="success"
          primary="Email Us"
          secondary="Get a response directly to your inbox"
          link="mailto:help@letshum.com"
        />
      </List>
    </RegAppBar>
  );
};

export default PageActivateHelp;
