import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Typography } from "@mui/material";
import PageAppBar from "../Shared/PageAppBar";
import PageHeading from "../Shared/PageHeading";
import PageBottomBtn from "../Shared/PageBottomBtn";

const PageSpeedDowngrade = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { api } = useOutletContext();
  const [callErr, setCallErr] = useState();

  const targetSpeed = location?.state?.targetSpeed;

  useEffect(() => {
    if (!targetSpeed) {
      navigate("/speed", { replace: true });
    }
  }, [targetSpeed, navigate]);

  if (!targetSpeed) {
    return <Typography>Loading...</Typography>;
  }

  const handleDowngrade = () => {
    api
      .setPlanSpeed(targetSpeed)
      .then((resp) => {
        navigate("/home", { state: { snackbar: resp.data } });
      })
      .catch((err) => {
        setCallErr(err.message);
      });
  };

  return (
    <PageAppBar title="Downgrade Plan">
      <PageHeading>Are you sure you want to downgrade your speeds?</PageHeading>
      <Typography sx={{ mb: 2 }}>
        If you downgrade your service now, you will continue to enjoy the higher
        tier speeds through the last day of this month.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Your service will be changed to your new plan starting on the 1st of the
        next month.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        You can always upgrade your service again through the portal at any
        time!
      </Typography>
      {callErr && (
        <Alert severity="error" variant="filled">
          {callErr}
        </Alert>
      )}
      <PageBottomBtn btnText="Confirm" onClick={handleDowngrade} />
    </PageAppBar>
  );
};

export default PageSpeedDowngrade;
