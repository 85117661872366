import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Alert, Button } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

const UpgradeForm = () => {
  const { inviteId } = useParams();

  const [submitErrMsg, setSubmitErrMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/upgrade/${inviteId}/complete`,
      },
    });

    if (error) {
      setSubmitErrMsg(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button type="submit" variant="contained" disabled={!stripe}>
        Upgrade Service
      </Button>
      {submitErrMsg && (
        <Alert sx={{ mt: 2 }} color="error">
          {submitErrMsg}
        </Alert>
      )}
    </form>
  );
};

export default UpgradeForm;
