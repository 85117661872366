import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import LayoutActivate from "./Layouts/LayoutActivate";
import LayoutUpgrade from "./Layouts/LayoutUpgrade";
import LayoutLoggedIn from "./Layouts/LayoutLoggedIn";
import PageActivateWelcome from "./Pages/ActivateFlow/PageActivateWelcome";
import PageActivateBasics from "./Pages/ActivateFlow/PageActivateBasics";
import PageActivateLocation from "./Pages/ActivateFlow/PageActivateLocation";
import PageActivateSpeed from "./Pages/ActivateFlow/PageActivateSpeed";
import PageActivatePayment from "./Pages/ActivateFlow/PageActivatePayment";
import PageActivateComplete from "./Pages/ActivateFlow/PageActivateComplete";
import PageActivateNetwork from "./Pages/ActivateFlow/PageActivateNetwork";
import PageActivateNetSetup from "./Pages/ActivateFlow/PageActivateNetSetup";
import PageLogin from "./Pages/PageLogin";
import PageHome from "./Pages/PageHome";
import PageAccount from "./Pages/PageAccount";
import PageNotFound from "./Pages/PageNotFound";
import PageHelp from "./Pages/PageHelp";
import PageSpeed from "./Pages/PageSpeed";
import PagePassword from "./Pages/PagePassword";
import PageLogout from "./Pages/PageLogout";
import PageSpeedDowngrade from "./Pages/PageSpeedDowngrade";
import PageSpeedUpgrade from "./Pages/PageSpeedUpgrade";
import PageSpeedUpgradeConfirm from "./Pages/PageSpeedUpgradeConfirm";
import PageActivateHelp from "./Pages/ActivateFlow/PageActivateHelp";

const App = ({ initialSessionToken }) => {
  const [sessionToken, setSessionToken] = useState(initialSessionToken);
  const handleNewSessionToken = (newToken) => {
    window.localStorage.setItem("session", newToken);
    setSessionToken(newToken);
    window.Intercom("shutdown");
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<PageLogin setSessionToken={handleNewSessionToken} />}
      />
      <Route path="/activate/help" element={<PageActivateHelp />} />
      <Route path="/activate/:inviteId" element={<LayoutActivate />}>
        <Route path="welcome" element={<PageActivateWelcome />} />
        <Route path="basics" element={<PageActivateBasics />} />
        <Route path="location" element={<PageActivateLocation />} />
        <Route path="network" element={<PageActivateNetwork />} />
        <Route path="netsetup" element={<PageActivateNetSetup />} />
      </Route>
      <Route path="/upgrade/:inviteId" element={<LayoutUpgrade />}>
        <Route path="speed" element={<PageActivateSpeed />} />
        <Route path="payment" element={<PageActivatePayment />} />
      </Route>
      <Route
        path="/upgrade/:inviteId/complete"
        element={<PageActivateComplete />}
      />
      <Route
        element={
          <LayoutLoggedIn
            sessionToken={sessionToken}
            setSessionToken={handleNewSessionToken}
          />
        }
      >
        <Route path="/home" element={<PageHome />} />
        <Route path="/account" element={<PageAccount />} />
        <Route path="/change-password" element={<PagePassword />} />
        <Route path="/speed">
          <Route index element={<PageSpeed />} />
          <Route path="downgrade" element={<PageSpeedDowngrade />} />
          <Route path="upgrade" element={<PageSpeedUpgrade />} />
          <Route path="upgrade/confirm" element={<PageSpeedUpgradeConfirm />} />
        </Route>
        <Route path="/logout" element={<PageLogout />} />
        <Route path="/help" element={<PageHelp />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
