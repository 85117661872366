import ky from "ky";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise = null;

export const getStripePromise = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};

/**
 * Creates an API URL from an array of path parts
 *
 * This expects that REACT_APP_API_ROOT is defined, correct and has a trailing slash.
 *
 * @param {string[]} pathParts
 * @return {string}
 */
const genApiUrl = (pathParts) => {
  const base = process.env.REACT_APP_API_ROOT;
  const parts = pathParts.join("/");
  return `${base}${parts}`;
};

export const login = async (email, password) => {
  const body = JSON.stringify({ email, password });
  const url = genApiUrl(["resi", "login"]);
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  };
  const req = new Request(url, opts);

  const response = await fetch(req);
  return response.json();
};

export const verifyToken = async (token) => {
  const url = genApiUrl(["activations", token]);
  return await ky.get(url).json();
};

/**
 * Registers a new user.
 *
 * @param {string} token
 * @param {{
 *    name_first: string,
 *    name_last: string,
 *    phone: string,
 *    email: string,
 *    password: string,
 *    birthday: string,
 *    service_option: number,
 *    ssid: string,
 *    wifi_key: string
 * }} payload
 * @returns {Promise<void>}
 */
export const register = async (token, payload) => {
  const url = genApiUrl(["activations", token, "activate"]);
  return await ky
    .post(url, { json: payload, redirect: "error", timeout: 120000 })
    .json();
};

export const verifyTokenUpgrade = async (token) => {
  const url = genApiUrl(["activations", token, "upgrade"]);
  return await ky.get(url).json();
};

export const startPaymentByToken = async (token, serviceOption) => {
  const url = genApiUrl(["activations", token, "upgrade"]);
  return await ky
    .post(url, { json: { serviceOption: serviceOption.toString() } })
    .json();
};

export const finishPaymentByToken = async (token, pintent) => {
  const urlParts = ["activations", token, "finish"];
  if (pintent) {
    urlParts.push(pintent);
  }
  const url = genApiUrl(urlParts);
  return await ky.post(url).json();
};

export const verifySessionToken = async (sessionToken) => {
  const url = genApiUrl(["resi", "session", sessionToken]);
  return await ky.get(url).json();
};
