import { Typography } from "@mui/material";

const ActivatePageTitle = ({ children }) => {
  return (
    <Typography sx={{ mb: 2 }} variant="h6">
      {children}
    </Typography>
  );
};

export default ActivatePageTitle;
