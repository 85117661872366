import { List, Typography } from "@mui/material";
import PageHeading from "../Shared/PageHeading";
import PageAppBar from "../Shared/PageAppBar";
import SpeedIcon from "@mui/icons-material/Speed";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import IconMenuItem from "../Shared/IconMenuItem";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import SuccessSnackbar from "../Shared/SuccessSnackbar";

const MainMenu = () => {
  return (
    <List>
      <IconMenuItem
        Icon={SpeedIcon}
        iconColor="primary"
        primary="Change my internet speed"
        secondary="Upgrade or downgrade internet speeds"
        link="/speed"
      />
      <IconMenuItem
        Icon={SettingsIcon}
        iconColor="secondary"
        primary="Edit my account"
        secondary="Update your account details"
        link="/account"
      />
      <IconMenuItem
        Icon={HelpIcon}
        iconColor="success"
        primary="I need help"
        secondary="No problem, our team is here to help!"
        link="/help"
      />
    </List>
  );
};

const PageHome = () => {
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(
    location?.state?.snackbar || ""
  );

  const handleSnackbarClose = () => setSnackbarOpen("");

  return (
    <>
      <PageAppBar title="Home">
        <PageHeading>Hum Resident Portal</PageHeading>
        <Typography sx={{ mb: 2 }}>What can we help you with today?</Typography>
        <MainMenu />
      </PageAppBar>
      <SuccessSnackbar
        open={!!snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarOpen}
      />
    </>
  );
};

export default PageHome;
