import LoginForm from "./PageHome/LoginForm";
import { AppBar, Box, Toolbar } from "@mui/material";
import { grey } from "@mui/material/colors";
import logo from "../../assets/logo-white.svg";

const PageLogin = ({ setSessionToken }) => {
  return (
    <>
      <AppBar>
        <Toolbar>
          <img
            src={logo}
            alt="Hum logo"
            style={{
              "background-color": "blue",
              "mask-image": `url(${logo})`,
              "-webkit-mask-image": `url(${logo})`,
            }}
          />
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          px: 2,
          pb: 6,
          minHeight: "100vh",
          maxHeight: "100%",
          bgcolor: grey[300],
        }}
      >
        <Toolbar />
        <LoginForm setSessionToken={setSessionToken} />
      </Box>
    </>
  );
};

export default PageLogin;
