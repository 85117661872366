import { useEffect, useState } from "react";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { Navigate, useOutletContext } from "react-router";
import ActivatePageTitle from "./ActivatePageTitle";
import { register } from "../../../publicApi";
import { dateObjToDateStr } from "../../../utility";
import { useParams } from "react-router-dom";

const payloadFromModel = (model) => {
  const pl = model.reduce((acc, val, key) => {
    acc[key] = val.clean;
    return acc;
  }, {});
  pl.birthday = dateObjToDateStr(pl.birthday);
  return pl;
};

const Success = ({ inviteId }) => (
  <>
    <Typography sx={{ my: 4 }} variant="body2">
      Enjoy your Hum internet!
    </Typography>
    <Navigate to={`/upgrade/${inviteId}`} />
  </>
);

const Failure = ({ msg }) => (
  <>
    <ActivatePageTitle>Oops!</ActivatePageTitle>
    <Alert color="error">{msg}</Alert>
  </>
);

const ShowProgress = () => <CircularProgress />;

const PageActivateNetSetup = () => {
  const { inviteId } = useParams();
  const [callResult, setCallResult] = useState(false);
  const [inviteInfo, wizardInfo] = useOutletContext();

  useEffect(() => {
    const payload = payloadFromModel(wizardInfo);
    register(inviteInfo.reg_key, payload)
      .then((resp) => {
        if (resp.status === "ok") {
          setCallResult(true);
          return;
        }
        setCallResult(resp.message);
      })
      .catch((err) => {
        setCallResult(err.message);
      });
  }, [inviteInfo.reg_key, wizardInfo]);

  return (
    <>
      <ActivatePageTitle>Setting Up Your Network</ActivatePageTitle>
      <Typography variant="body2">
        Give us about a minute while we setup your WiFi Network!
      </Typography>
      {callResult === true && <Success inviteId={inviteId} />}
      {callResult === false && <ShowProgress />}
      {typeof callResult === "string" && <Failure msg={callResult} />}
    </>
  );
};

export default PageActivateNetSetup;
