import SpeedSelectionCard from "./SpeedSelectionCard";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import {
  dateFirstOfNextMonthShortFormat,
  formatPrice,
  formatSpeedTier,
} from "../../utility";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";

const TEXT_STARTER =
  "This speed tier is included with your monthly rent / amenities payment. Works great for the average household that needs to work, learn, and play from home.";
const TEXT_FASTER =
  "Blazing fast internet! No more slow downloads, choppy work calls, or glitchy video games. All speed, all the time.";
const TEXT_FASTEST =
  "The cream of the crop & the new standard for the web! Built to handle streaming multiple 8K videos, host groundbreaking live streams, or dominate the web with unparalleled speed, this service is designed to provide the ultimate browsing experience!";

const CHIPS_STARTER = [
  "Remote Learning",
  "Remote Work",
  "ESports Ready",
  "1080p Video Streaming",
];
const CHIPS_FASTER = [
  "Ideal for Multiple Users",
  "Blazing Downloads",
  "1080p Game Streaming",
  "4k Video Streaming",
  "Remote Learning",
];
const CHIPS_FASTEST = [
  "Optimized for Content Creation and Streaming",
  "Built for Professionals",
  "Supports Multiple 8K Video Streams",
  "8k Gamestream Ready",
];

const assembleSubtitle = (
  mainText,
  currentSpeed,
  optionSpeed,
  plannedSpeed
) => {
  if (!currentSpeed) {
    return mainText;
  }

  if (currentSpeed !== optionSpeed && plannedSpeed !== optionSpeed) {
    return mainText;
  }

  if (currentSpeed === optionSpeed) {
    return (
      <>
        {mainText}{" "}
        <Box component="span" sx={{ color: "success.light" }}>
          (Current Plan)
        </Box>
      </>
    );
  }

  const downgradeDate = dateFirstOfNextMonthShortFormat();
  return (
    <>
      {mainText}{" "}
      <Box component="span" sx={{ color: "warning.light" }}>
        (Scheduled - {downgradeDate})
      </Box>
    </>
  );
};

const SpeedSelectionCardList = ({
  serviceOptions,
  selected,
  onChange,
  currentSpeed,
  plannedSpeed,
}) => {
  const sel = selected || 0;

  return (
    <>
      <SpeedSelectionCard
        onClick={() => onChange(0)}
        title={`Starter ${formatSpeedTier(serviceOptions.starter.speed)}`}
        subtitle={assembleSubtitle(
          "Included",
          currentSpeed,
          serviceOptions.starter.speed,
          plannedSpeed
        )}
        icon={<RocketLaunchOutlinedIcon color="primary" />}
        bodyText={TEXT_STARTER}
        selected={sel === 0}
        chipList={CHIPS_STARTER}
      />
      {serviceOptions.faster && (
        <SpeedSelectionCard
          onClick={() => onChange(1)}
          title={`Faster ${formatSpeedTier(serviceOptions.faster.speed)}`}
          subtitle={assembleSubtitle(
            `+${formatPrice(serviceOptions.faster.cost)}/Month`,
            currentSpeed,
            serviceOptions.faster.speed,
            plannedSpeed
          )}
          icon={<ElectricBoltIcon sx={{ color: grey[200] }} />}
          bodyText={TEXT_FASTER}
          selected={sel === 1}
          invertIcon
          chipList={CHIPS_FASTER}
        />
      )}
      {serviceOptions.fastest && (
        <SpeedSelectionCard
          onClick={() => onChange(2)}
          title={`Fastest ${formatSpeedTier(serviceOptions.fastest.speed)}`}
          subtitle={assembleSubtitle(
            `+${formatPrice(serviceOptions.fastest.cost)}/Month`,
            currentSpeed,
            serviceOptions.fastest.speed,
            plannedSpeed
          )}
          icon={<ElectricBoltIcon sx={{ color: grey[200] }} />}
          bodyText={TEXT_FASTEST}
          selected={sel === 2}
          invertIcon
          chipList={CHIPS_FASTEST}
        />
      )}
    </>
  );
};

export default SpeedSelectionCardList;
